
import {defineComponent} from "vue";

import SrcPackage2 from "@/components/reservations/new/SrcPackage2.vue";
import Step1 from "@/components/new-reservation/fly-ticket/Step1.vue";

export default defineComponent({
    name: "reservation-new-2",
    components: {


    },
    setup() {
        return {

        };
    },
});
